import type { Theme } from '@mui/material'
import type { SystemStyleObject } from '@mui/system'

import { useProduct } from '@/providers/p/ProductProvider'
import type { Image, Video } from '@/types'

export const IMAGE_GALLERY_HEIGHT = 400
export const MAIN_IMAGE_MAX_WIDTH = 632
export const THUMBS_SIDEBAR_WIDTH = 71

export const THUMBS_SIDEBAR_HEIGHT = 53
export const HOVER_BOX_SHADOW_SIZE = 2
export const IMAGE_GALLERY_BUTTON_DISABLED_CLASS = 'ImageGallery-button-disabled'
export const DESKTOP_GALLERY_CLASS = 'ImageGalleryDesktop'
export const MOBILE_GALLERY_CLASS = 'ImageGalleryMobile'

export const commonNavButtonStyles: SystemStyleObject<Theme> = {
  position: 'absolute',
  zIndex: 1,
  bgcolor: 'primary.main',
  color: 'white',
  '&:hover': { bgcolor: 'primary.light', color: 'white' },
  [`&.${IMAGE_GALLERY_BUTTON_DISABLED_CLASS}`]: {
    display: 'none',
  },
  borderRadius: '2px',
}

export const mobileNavButtonStyles: SystemStyleObject<Theme> = {
  top: '50%',
  transform: 'translateY(-50%)',
}

export const desktopNavButtonStyles: SystemStyleObject<Theme> = {
  left: '50%',
  transform: 'translateX(-50%)',
}

export type ImageAsset = { isImage: true } & Image
export type VideoAsset = { isVideo: true } & Video
export type Asset = ImageAsset | VideoAsset

export const useNormalizeAssets = () => {
  const { product } = useProduct()
  const { videos = [], images = [], name } = product

  const assets = [
    ...images.map((image) => ({ isImage: true, ...image })),
    ...videos.map((video) => ({ isVideo: true, ...video })),
  ] as Asset[]

  const hasMoreThanOneAsset = assets.length > 1

  return { assets, hasMoreThanOneAsset, productName: name }
}
